@import "variables";

// Globals
*,
*::before,
*::after {
  box-sizing: border-box; }

body {
  margin: 0 auto;
  font-family: $font-stack-body;
  font-weight: 400;
  font-size: 16px;
  color: $color-on-background;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //background-image: url('background/moroccan-flower.png')
  //background-image: url('background/circles-light.png')
 }  //opacity: 0.15

code {
  font-family: $font-stack-mono; }

h1 {
  font-family: $font-stack-headings;
  font-weight: 500;
  font-size: 3rem; }

img {
  max-width: 100%;
  display: block; }

header,
footer {
  width: 100%;
  margin: 0 auto;
  padding: 0 1em;
  cursor: default;
  background: $color-primary-dark;
  color: $color-on-primary-dark;

  // Flexbox to center children vertically when height is <100%
  //display: flex
  //flex-direction: column
  //justify-content: center

  .header-content,
  .footer-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 100%; } }

//*** Flexbox Debugging ***
//header,
//footer
//  border: 1px solid darkred
//  .header-content,
//  .footer-content
//    border: 1px solid lightgreen
//
//  .header-content > *,
//  .footer-content > *
//    border: 1px solid darkorange
//*** Flexbox Debugging ***

header {
  height: $header-height;
  min-height: 40px;
  font-size: 1.5rem;
  .header-content {
    a {
      margin-right: 1em;
      color: $color-secondary;
      opacity: 0.85;
      text-decoration: none; }
    .header-active {
      color: $color-on-primary-dark-alt;
      opacity: 1; }
    a:hover,
    a:focus,
    .header-hovers {
      color: $color-background; } }
  .header-content > * {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: -0.25em;
    padding: 0 0.25em; } }


footer {
  height: $footer-height;
  min-height: 30px;
  font-size: 0.85rem;
  margin: 0;
  .footer-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    p {
      margin: 0; }
    a {
      color: inherit; }
    a:hover,
    a:focus {
      color: inherit; }
    img {
      max-height: 2vh; } } }

// App Contents
.app-container {
  margin: 0 auto;
  height: 100vh;
  width: 100vw;
  max-width: $app-max-width;

  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.content-container {
  width: 100%;
  display: initial;
  margin-bottom: auto; }

// Page Title
.page-title-container {
  margin: 0 auto;
  cursor: default;
  overflow: hidden;
  white-space: nowrap;
  hr {
    margin-top: 0; }
  img {
    max-height: 100px;
    display: inline-block;
    margin-right: 2px; } }

// Content
.content-container {
  padding: 6px 6px;
 }  //border: 1px dashed black

// Add Beverage Form
.add-beverage-container {
  //border: 1px solid orange
  display: flex;
  justify-content: flex-end;

  .floating-button-container {
    img {
      position: initial;
      cursor: pointer;
      margin: 0; } } }

#add-beverage-popover {
  display: inherit; }

.popover {
  min-width: 350px;
  min-height: 550px; }

@media (max-width: $app-max-width) {
  .add-beverage-container {
    position: fixed;
    bottom: 3em;
    right: 0.5em; } }

// TODO: Add tooltips to icons https://www.bitdegree.org/learn/css-tooltip
.add-beverage-form-group {
  margin: 5px 5px 10px 5px; }

// Buttons
.btn {
  height: 2.5em;
  text-align: center;
  margin: 0;
  padding: 0.35em 0.9em;
  border-radius: 5px;
  outline: none; }

.btn-sm {
  height: 2em;
  text-align: center;
  margin: 0.1em 0.2em 0.1em 0;
  padding: 0.28em 0.8em;
  border-radius: 5px; }

.btn-primary {
  background: $color-primary;
  color: $color-on-primary;
  border: 2px solid $color-background; }
.btn-primary:hover,
.btn-primary:focus {
  border: 2px solid $color-primary-light; }
.btn-primary:active {
  border: 2px solid $color-primary-dark; }

.btn-primary-outline {
  background: $color-primary-lighterer;
  color: $color-on-primary-light;
  font-weight: 600;
  border: 1px solid $color-background; }
.btn-primary-outline:hover,
.btn-primary-outline:focus {
  //border: 2px solid $color-primary
  background: $color-primary-lighter;
 }  //border: 1px solid $color-primary
.btn-primary-outline:active {
  background: $color-primary-lighter;
  //border: 1px solid $color-primary
 }  //border: 2px solid $color-primary-dark

.btn-reset-outline {
  background: $color-background-darker-1;
  color: $color-on-background;
  font-weight: 400;
  border: 2px solid $color-background; }
.btn-reset-outline:hover,
.btn-reset-outline:focus {
  background: $color-background-darker-2;
  border: 2px solid $color-background; }
.btn-reset-outline:active {
  background: $color-background-darker-3;
  border: 2px solid $color-background; }

.btn-danger {
  background: $color-danger;
  color: $color-on-danger;
  border: 2px solid $color-background; }
.btn-danger:hover,
.btn-danger:focus {
  border: 2px solid $color-danger-light; }
.btn-danger:active {
  border: 2px solid $color-danger-dark; }

// Inputs
input,
select {
  padding: 0.35em 0.65em;
  border: 1px solid $color-outline;
  background-color: $color-input-background; }

@media (max-width: 899px) {
  .btn-add-floating {
    display: none; } }
