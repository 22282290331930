@import "variables";

// Picklists
.picklist-container {
  padding: 5px;
  margin: 0;
  display: flex;
  flex-direction: column; }

.picklist-values-container {
  display: grid;
  grid-template: 1fr / 1fr 1fr 2fr;
  grid-gap: 2px;
  //height: 500px
  .picklist-nav-parent {
    background: whitesmoke;
    list-style: none;
    text-align: center;
    li:hover,
    li:focus {
      background: lightyellow; }
    a {
      text-decoration: none;
      text-transform: capitalize;
      width: 100%;
      padding: 2px 10px; }
    //a:hover
 }    //  background: whitesmoke

  .picklist-nav-child {
    background: #e6f2ff;
    justify-content: space-between;
    width: max-content;
    input {
      margin: 1px 0; } }

  .picklist-nav-grandchild {
    background: #c3deff; } }


//.picklist-tab-values
//  border: 1px brown dotted
//  width: 80px
//  height: 20px
