@import "variables";

// Globals
input,
button {
  font-family: inherit;
  outline: transparent; }

.list-item-grid {
  margin: 0;
  border-radius: 0 0 6px 6px;
  display: grid;
  grid-gap: 3px;
  background: linear-gradient(135deg, black 25%, transparent 25%) -50px 0, linear-gradient(225deg, black 25%, transparent 25%) -50px 0, linear-gradient(315deg, black 25%, transparent 25%), linear-gradient(45deg, black 25%, transparent 25%);
  background-size: 0.31em 0.31em;
  background-color: #343434; }

.grid-cell {
  opacity: 1;
  display: flex;
  background: #f0f0f0;
  flex-direction: column;
  align-content: space-between;
  //border: 1px solid gray
  border-radius: 3px;
  margin: 0 0.1em;
  z-index: 10; }

.list-item-key {
  //border: 1px dashed saddlebrown
  display: flex;
  align-items: center;
  min-width: min-content;
  margin: 0.1em;
  img {
    margin: 0 0.2em 0 0;
    width: 100%;
    //border: 1px solid red
    max-height: 18px; }  // skinny icons like € can get really tall
  label {
    margin: 0;
    white-space: nowrap;
    word-wrap: break-spaces;
    min-width: min-content;
    width: 100%;
 }    //border: 1px solid hotpink

  .list-item-key-icon-container {
    // Set a consistent size for the label icons
    height: 18px;
    width: 18px;
    min-height: 18px;
    min-width: 18px;
    // Center the img vertically
    display: flex;
    align-items: center;
    // Color all SVGs gray
    filter: brightness(0) saturate(100%) invert(54%) sepia(7%) saturate(0%) hue-rotate(226deg) brightness(92%) contrast(83%);
    // SVG color conversion tool: https://codepen.io/sosuke/pen/Pjoqqp
    //  -> Prefix with _brightness(0) saturate(100%)_ to ensure all SVGs start completely black.
 } }    //  -> Now we can set SVG color to #808080 gray.

.list-item-value {
  display: flex;
  align-items: center;
  margin: 0.1em;
  //border: 1px dashed red
  border-radius: 6px; }

.clickable-toggle {
  img {
    height: 18px;
    width: 18px;
    max-width: 18px;
    max-height: 18px;
    cursor: pointer; } }

.input-group {
  display: grid;
  grid-template: 1fr / 3fr 7fr;
  //margin: auto
  //.list-item-value,
  //.list-item-value-icon
 }  //  margin: auto 0  // center vertically

.input-qty-container {
  grid-area: qty;
  flex-direction: row;
  justify-content: center;
  flex-grow: 0; }

.input-dates-container {
  grid-area: dates;
  //border: 1px solid blue
  display: flex;
  flex-direction: column;
  //label
  //  width: 120px
  //  min-width: 120px
 }  ////border: 1px dashed black

.input-styles-container {
  grid-area: styles; }

.input-clickable-toggles-container {
  grid-area: toggles;
  display: flex;
  flex-direction: column;
  //.list-item-key
 }  //  min-width: 115px

.input-size-loc-container {
  grid-area: size-loc;
  display: flex;
  flex-direction: row; }

//.input-qty-container
//  input
//    width: 65px

//.input-bottle-date
//  .list-item-key
//    min-width: 115px
//  input
//    max-width: 140px

//.input-styles-container
//  select
//    width: 170px

.input-longtext-container {
  grid-area: longtext;
  display: flex;
  flex-direction: column;
  input {
    min-width: 300px; } }

//.input-picklist-sm
//  width: 110px

// Buttons
.eli-buttons {
  grid-area: action-buttons;
  display: flex;
  flex-direction: row;
  align-items: center;
  //margin: 0 0.35rem
  //button
 }  //  margin-right: 0.35rem

.button-container {
  grid-area: action-buttons;
  //display: flex
  //align-items: center
  margin: 0.2rem;
  padding: 0 0.2rem;
  //display: none
  .btn-reset-outline {
    margin-right: 0.2rem; }
  .btn,
  .btn-del {
    outline: none;
    border: 2px solid transparent; } }

.del-button {
  grid-area: del-button;
  display: flex;
  margin-right: 0.25em;
  justify-content: flex-end; }

