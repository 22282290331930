// Fonts
$font-stack-headings: 'Montserrat', sans-serif;
$font-stack-body: 'Lato', sans-serif;
$font-stack-mono: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;

// Colors
$color-primary: #0068d5;
$color-primary-dark: #003fa3;
$color-primary-light: #5f95ff;
$color-primary-lighter: #69a4ff;
$color-primary-lighterer: #c3deff;
$color-primary-lightest: #e6f2ff;
$color-on-primary: #f5f5f5;
$color-on-primary-light: #121212;
$color-on-primary-dark: #f5f5f5;
$color-on-primary-dark-alt: #f0f0f0;

$color-secondary: #ccc;
$color-background: #fff;
$color-on-background: #121212;
$color-background-darker-1: #eee;
$color-background-darker-2: #ccc;
$color-background-darker-3: #aaa;
$color-danger: #B81F11;
$color-danger-light: #d78f8c;
$color-danger-dark: #8C180D;
$color-on-danger: #f5f5f5;
$color-on-danger-light: #121212;
$color-on-danger-dark: #f5f5f5;

$color-primary-complementary: #d56e00;
$color-primary-analogous-1: #00d1d5;
$color-primary-analogous-2: #0400d5;
$color-primary-triadic-1: #6e00d5;
$color-primary-triadic-2: #d50067;

$color-input-background: #fff;
$color-outline: #808080;


// Page Layout
$header-height: 3em;
$footer-height: $header-height - 1em;
$app-max-width: 1200px;

// Debugging
$border1: 1px solid red;
$border2: 1px solid darkgreen;
$border3: 1px solid lightblue;
$border4: 1px solid black;
