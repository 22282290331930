/* Adding all grid-template-area definitions since SASS doesn't support multi-line entries */

.list-item-grid {
	grid-template: 0.1em repeat(4, auto) 0.1em / 0.1em repeat(3, auto) 0.1em;
	grid-template-areas:
			". ... ..... ....... ."
			". qty dates toggles ."
			". size-loc dates toggles ."
			". styles longtext longtext ."
			". action-buttons . del-button ."
			". .............. . .......... .";
}

@media (max-width: 740px) {
	.list-item-grid {
		grid-template: auto / auto;
		grid-template-areas:
			". ... . ."
			". qty qty ."
			". size-loc size-loc ."
			". styles styles ."
			". dates dates ."
			". toggles toggles ."
			". longtext longtext."
			". action-buttons del-button ."
			". .............. .......... .";
	}

	.add-bev {
		justify-content: flex-end;
	}
}
